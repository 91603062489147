import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Sidebar = ({ isDesktop }) => {
  const navigate = useNavigate(); // Create a navigate function

  // Handler function to navigate to a specific route
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div
      className={`lg:flex lg:${isDesktop ? 'w-1/6' : 'hidden'}  p-4 flex-col justify-between sticky`}
      style={{ background: 'black' }}
    >
      <div
      //  style={{ position: 'fixed' }}
      >
        <div>
          <img 
           onClick={() => handleNavigation('/')}
          src="/logo.png" className="w-36 h-auto" alt="Logo" />
          <nav className="space-y-4 mt-5">
            <div
              className={`flex flex-col items-start justify-start gap-[30px] z-[1] text-left text-white font-text-md-medium text-[20px]`}
            >
              <button
                onClick={() => handleNavigation('/')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Home
              </button>
              <button
                onClick={() => handleNavigation('/services')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Our Services
              </button>
              <button
                onClick={() => handleNavigation('/product')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Our Products
              </button>
              <button
                onClick={() => handleNavigation('/ourwork')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Our Works
              </button>
              <button
                onClick={() => handleNavigation('/about-us')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                About Us
              </button>
              <button
                onClick={() => handleNavigation('/whyIQ')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Why Choose Us
              </button>
              <button
                onClick={() => handleNavigation('/contactUs')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Contact Us
              </button>
              <button
                onClick={() => handleNavigation('/Privacy-Policy')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Privacy Policy
              </button>
              <button
                onClick={() => handleNavigation('/Terms-Condition')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Terms&Condition
              </button>
            </div>
            <div>
            <button onClick={() => handleNavigation('/demo')} className="max-w-fit py-3 px-4 m-0 bg-[#FFA500] text-black rounded font-semibold transition duration-300">
          Free Demo Request
        </button>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

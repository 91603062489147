import React from 'react';
import { motion } from 'framer-motion';
import { Box, Grid, Typography, TextField, Checkbox, Button, Container, Slider } from '@mui/material';
import { Row } from 'react-bootstrap';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
const variant = {
  initial: { opacity: 0, y: 100 },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 1, staggerChildren: 0.1 },
  },
};
const contactData = [
  {
    title: "India Operation",
    email: "info@photonsoftwares.com",
    phone: "+91 8400083557",
    address: (
      <>
        Photon Software, India <br />
        Plot No.242/G3, Nitikhand-1 Indirapuram, NCR India
      </>
    ),
  },
  {
    title: "UAE Operations",
    email: "sales@rajeebtech.ae",
    phone: "+971 55 157 9784",
    link: "https://www.rajeebtech.ae",
    address: (
      <>
        Photon Softwares RAJEEB TECHNOLOGIES LLC <br />
        PO Box No: 112420 DUBAI, UAE.
      </>
    ),
  },
  {
    title: "Canada Operation",
    email: "info@photonsoftwares.com",
    phone: "+16473343321",
    address: (
      <>
        Photon Software, Canada <br />
        150 King St W Suite # 200, Toronto, ON M5H 1J9
      </>
    ),
  },
  {
    title: "Australia Operation",
    email: "info@photonsoftwares.com",
    phone: "+61 450 755 589",
    address:( <>
    Photon Software, <br/>
    Australia
    </>
  ),
  },
];

function Contactus({ isDesktop }) {
  const [value, setValue] = React.useState([10000, 80000]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function valuetext(value) {
    return `${value}°C`;
  }
  const ContactInfo = ({ title, email, phone, address, icon, link }) => (
    <div className="grid grid-cols-1 gap-4 text-sm">
      <div className="flex items-center space-x-2 text-nowrap">
        <span>{title}</span>
      </div>
      {email && (
        <div className={`flex items-center space-x-2 ${isDesktop ?'text-[15px]':"text-xl"} `}>
          <EmailIcon className="h-4 w-4 text-[#FFA500]" />
          {link ? (
            <div className='flex flex-col'>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {email}
            </a>
              <span>{link}</span>
            </div>
          ) : (
            <span>{email}</span>
          )}
        </div>
      )}
      {phone && (
        <div className="flex items-center space-x-2">
          <LocalPhoneIcon className="h-4 w-4 text-[#FFA500]"/>
          <span className='text-nowrap'>{phone}</span>
        </div>
      )}
      {address && (
        <div className="flex items-center space-x-2">
          <LocationOnIcon className="h-4 w-4 text-[#FFA500]" />
          <span>{address}</span>
        </div>
      )}
    </div>
  );

  return (
<Container  id="contactUs" className={`${isDesktop ? "h-fit" : "h-fit"} overflow-hidden`}>

    <Box className={`lg:${isDesktop ? 'w-3/4' : 'w-full' } `}>
      <motion.div variants={variant} initial="initial" whileInView="animate">
      <div className="w-full flex flex-col items-center justify-center max-w-full">
              <Row className="mt-2 mb-3">
              <Typography
              variant="h3"
              className="text-white font-inherit font-bold "
            >
              {/* <div className="flex items-center justify-center h-40 w-40 bg-black"> */}
              {/* </div>   */}
              Contact Us
            </Typography>
              </Row>
              <Row className="mt-2 mb-3">
                <h6 className="m-0 text-center font-bold ">Get in touch with us today and let us help you with any questions or inquiries you may have.</h6>
              </Row>
            </div>
      <div className="min-h-screen bg-black bg-opacity-50 text-gray-100 p-4 md:p-8">
      <form className={`${isDesktop ?'mx-5':""} max-w-fit space-y-6`}>
        <div className={`grid ${isDesktop?"grid-cols-2":"grid-cols-1"} gap-6`}>
          <div className='bg-[#262626] bg-opacity-50 p-2 rounded px-4'>
            <label htmlFor="fullName" className="block text-sm font-medium mb-1">Full Name</label>
            <input id="fullName" type="text" placeholder="Type here" className="w-full bg-[#262626] rounded-md p-2 text-white" />
          </div>
          <div  className='bg-[#262626] bg-opacity-50 p-2 rounded px-4'>
            <label htmlFor="email" className="block text-sm font-medium mb-1">Email</label>
            <input id="email" type="email" placeholder="Type here" className="w-full bg-[#262626] rounded-md p-2 text-white" />
          </div>
        </div>

        <div className='bg-[#262626] bg-opacity-50 p-2 rounded px-4'>
          <label className="block text-sm font-medium mb-2">Why are you contacting us?</label>
          <div className={`grid ${isDesktop?"grid-cols-2": "md:grid-cols-1"} gap-4`}>
            {['Web Design', 'Mobile App Design', 'Collaboration', 'Others'].map((option) => (
              <div key={option} className="flex items-center space-x-2">
                <input type="checkbox" id={option.toLowerCase().replace(' ', '-')} className="rounded bg-gray-800 border-gray-700 text-orange-500 focus:ring-orange-500" />
                <label htmlFor={option.toLowerCase().replace(' ', '-')}>{option}</label>
              </div>
            ))}
          </div>
        </div>

        <div  className='bg-[#262626] bg-opacity-50 p-2 rounded px-4'>
          <label className="block text-sm font-medium mb-2">Your Budget Range</label>
          <p className="text-sm text-gray-400 mb-2">Slide to indicate your budget range</p>
          <Box sx={{ width: "100%" }}>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        min={0} // Minimum value of the range
        max={100000} // Maximum value of the range
        step={1000}
        sx={{
          color: '#FFA500', // Custom color for the track and thumb
          '& .MuiSlider-thumb': {
            backgroundColor: '#FFA500', // Thumb color (the handle)
          },
          '& .MuiSlider-rail': {
            color: '#BDBDBD', // Color for the unselected portion (rail)
          },
        }}
      />
    </Box>
        </div>

        <div  className='bg-[#262626] bg-opacity-50 p-2 rounded px-4'>
          <label htmlFor="message" className="block text-sm font-medium mb-1">Your Message</label>
          <textarea id="message" placeholder="Type here" className="w-full bg-[#262626]  border border-gray-700 rounded-md p-2 text-white" rows={4}></textarea>
        </div>
        <div className='flex justify-center'>

        <button type="submit" className="w-fit bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded">
          Submit
        </button>
        </div>
         {/* <div className={`${isDesktop? 'flex flex-row gap-5 mx-5': 'flex flex-col gap-5'} `}>
        <div className={`grid ${isDesktop?"grid-cols-1": "md:grid-cols-1"} gap-4 text-sm`}>
          <div className='flex items-center space-x-2'>
          <span>India Operation</span>
          </div>
          <div className="flex items-center space-x-2">
          <EmailIcon className="h-4 w-4 text-[#FFA500]" />
            <span>info@photonsoftwares.com</span>
          </div>
          <div className="flex  items-center  space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-[#FFA500]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
            <span>+91 8400083557</span>
          </div>
          <div className="flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-[#FFA500]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <span>Photon Software, India <br/>
                  Plot No.242/G3,Nitikhand-1 Indirapuram,NCR India</span>
          </div>
        </div>
        <div
                  className={`grid ${
                    isDesktop ? "grid-cols-1" : "md:grid-cols-1"
                  } gap-4 text-sm`}
                >
                  <div className="flex items-center space-x-2">
                    <span>UAE Operations</span>
                  </div>
                  <div className="flex items-center space-x-2 w-fit">
                    
                    <EmailIcon className="h-4 w-4 text-[#FFA500]" />
                    <div className="flex flex-col">
                      <span>sales@rajeebtech.ae</span>
                      <a target="_blank" href="https://www.rajeebtech.ae">
                        https://www.rajeebtech.ae
                      </a>
                    </div>
                  </div>
                  <div className="flex  items-center  space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-[#FFA500]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                    <span>+971 55 157 9784</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-[#FFA500]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <span>
                      Photon Softwares RAJEEB TECHNOLOGIES LLC PO Box No: 112420
                      DUBAI, UAE.
                    </span>
                  </div>
                </div>
        <div className={`grid ${isDesktop?"grid-cols-1": "md:grid-cols-1"} gap-4 text-sm`}>
          <div className='flex items-center space-x-2'>
          <span>Canada Operation</span>
          </div>
          <div className="flex items-center space-x-2">
          <EmailIcon className="h-4 w-4 text-[#FFA500]" />
            <span>info@photonsoftwares.com</span>
          </div>
          <div className="flex  items-center  space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-[#FFA500]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
            <span>+16473343321</span>
          </div>
          <div className="flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-[#FFA500]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <span>Photon Software, Canada  <br/>
                  150 King St W Suite # 200, Toronto, ON M5H 1J9</span>
          </div>
        </div>
        <div className={`grid ${isDesktop?"grid-cols-1": "md:grid-cols-1"} gap-4 text-sm`}>
          <div className='flex items-center space-x-2'>
          <span>Australia Operation</span>
          </div>
          <div className="flex items-center space-x-2 w-fit">
          <EmailIcon className="h-4 w-4 text-[#FFA500]" />
            <span>info@photonsoftwares.com</span>
          </div>
          <div className="flex  items-center  space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-[#FFA500]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
            <span>+61 450 755 589</span>
          </div>
          <div className="flex items-center space-x-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-[#FFA500]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <span>Photon Software, Australia</span>
          </div>
        </div>
        </div> */}
        <div
      className={`${
        isDesktop ? "flex flex-row gap-5 mx-5" : "flex flex-col gap-5"
      }`}
    >
      {contactData.map((data, index) => (
        <ContactInfo key={index} {...data} />
      ))}
    </div>
      </form>
    </div>
        {/* <Box
       
          className="bg-gray1-100 py-16 px-4 mt-3 text-center font-poppins"
          sx={{
            pt: { xs: 1, sm: 2, md: 3 },
            pb: { xs: 2, sm: 4, md: 8 },
            maxWidth: '100%',
            mx: 'auto',
          }}
        >
          <Typography variant="h3" component="h3" className="font-bold  mb-2">
            Contact Us
          </Typography>
          <Typography variant="h6" className="font-semibold mb-2">
            Get In Touch
          </Typography>
          <Typography className="mb-8">
            We’d love to hear from you. Please fill out this form.
          </Typography>
          <Grid container className='mt-2' spacing={2} justifyContent="center">
            <Grid item xs={12} md={8}>
              <Box component="form" sx={{ '& .MuiTextField-root': { mb: 2 }, mx: 'auto' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      style={{background:"white", borderRadius:"10px"}}
                      label="First Name"
                      variant="outlined"
                      required
                      placeholder="First name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      style={{background:"white", borderRadius:"10px"}}
                      label="Last Name"
                      variant="outlined"
                      required
                      placeholder="Last name"
                    />
                  </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      style={{background:"white", borderRadius:"10px"}}
                      label="Email"
                      variant="outlined"
                      required
                      placeholder="you@company.com"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      style={{background:"white", borderRadius:"10px"}}
                      label="Phone Number"
                      variant="outlined"
                      required
                      placeholder="Mobile No. (Office)"
                    />
                  </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      style={{background:"white", borderRadius:"10px"}}
                      label="Message"
                      variant="outlined"
                      multiline
                      rows={4}
                      required
                    />
                  </Grid>
                  
                  <Grid className='mt-4' item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{background:"#3A526C"}}
                      sx={{ py: 2 }}
                    >
                      Send Message
                    </Button>
                  </Grid>
              
              </Box>
            </Grid>
          </Grid>
        </Box> */}
      </motion.div>
    </Box>

    </Container>
  );
}

export default Contactus;

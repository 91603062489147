import React, { useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';

function NavbarContainer() {
  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = () => {
    setExpanded(false);
  };
  const navigate = useNavigate(); // Create a navigate function
  const handleNavigation = (path) => {
    navigate(path);
    setExpanded(!expanded)
  }
  return (
    <Navbar
      className={`bg-black ${expanded?"":"bg-opacity-50"}`}
      style={{ width: "100%" }}
      variant="dark"
      expand="lg"
      sticky="top"
      expanded={expanded}
    >
      <Container className="container" fluid>
        <Navbar.Brand href="#" className="d-flex align-items-center">
          <img
            className="img-fluid mr-2"
            loading="lazy"
            alt=""
            src="/logo.png"
            width="50"
            height="50"
            onClick={()=>navigate("/")}
          />
          {/* <b className="text-white">Innovationquotient</b> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ml-auto mx-5">
            <div>
          <button
                onClick={() => handleNavigation('/')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Home
              </button>
              <button
                onClick={() => handleNavigation('/services')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Our Services
              </button>
              <button
                onClick={() => handleNavigation('/product')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Our Products
              </button>
              <button
                onClick={() => handleNavigation('/ourwork')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Our Works
              </button>
              <button
                onClick={() => handleNavigation('/about-us')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                About Us
              </button>
              <button
                onClick={() => handleNavigation('/whyIQ')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Why Choose Us
              </button>
              <button
                onClick={() => handleNavigation('/contactUs')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Contact Us
              </button>
              <button
                onClick={() => handleNavigation('/Privacy-Policy')}
                className="cursor-pointer fw-bold nav-link text-white"
              >
                Privacy Policy
              </button>
            </div>
            <div>
            <button onClick={() => handleNavigation('/demo')} className="cursor-pointer fw-bold nav-link  max-w-fit m-0 text-[#FFA500]  rounded font-semibold transition duration-300">
          Free Demo Request
        </button>
            </div>
          </Nav>
          {/* <Nav className="ml-auto">
            <img
              className="img-fluid mt-3 mr-3"
              loading="lazy"
              alt=""
              src="/group.svg"
              width="15"
              height="15"
            />
            <NavDropdown title="India" id="basic-nav-dropdown" className="mt-2 text-white">
              <NavDropdown.Item href="#action/3.1">Option 1</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Option 2</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Option 3</NavDropdown.Item>
            </NavDropdown>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarContainer;

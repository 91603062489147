import React from 'react';

export default function TermsAndConditions() {
  return (
    <div className="container p-9">
    <div className='min-h-screen bg-black bg-opacity-50 text-gray-100 p-4 md:p-8' style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1 className="text-3xl font-bold mb-6 text-center">
        Terms and Conditions for Photon Softwares
      </h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Welcome to Photon Softwares</h2>
        <p className="mb-4">
          These terms and conditions outline the rules and regulations for using Photon
          Softwares' website, located at{' '}
          <a
            href="https://photonsoftwares.com"
            className="text-blue-600 hover:underline"
          >
            https://photonsoftwares.com
          </a>
          .
        </p>
        <p className="mb-4">
          By accessing this website, you accept these terms and conditions in full. Do not
          continue to use Photon Softwares' website if you do not agree to all the terms
          and conditions stated on this page.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Definitions</h2>
        <ul className="list-disc pl-6 space-y-2">
          <li>
            "Client," "You," "Your": Refers to you, the person accessing this website and
            agreeing to the Company's terms.
          </li>
          <li>"The Company," "We," "Our," "Us": Refers to Photon Softwares.</li>
          <li>"Party," "Parties": Refers to both the Client and the Company.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Use of Cookies</h2>
        <p>
          Photon Softwares uses cookies to improve user experience. By accessing our
          website, you consent to the use of cookies in accordance with our Privacy
          Policy.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">License</h2>
        <p className="mb-4">
          Unless otherwise stated, Photon Softwares owns the intellectual property rights
          for all material on this website. All rights are reserved. You may access this
          website for personal use, subject to restrictions outlined in these terms.
        </p>
        <p className="mb-4">You must not:</p>
        <ul className="list-disc pl-6 space-y-2">
          <li>Republish, sell, rent, or sub-license material from this website.</li>
          <li>Reproduce, duplicate, or copy material without explicit permission.</li>
          <li>Redistribute content unless explicitly authorized.</li>
        </ul>
      </section>

      {/* Other sections */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-4">For questions or concerns regarding these terms, please contact us at:</p>
        <p>
          Email:{' '}
          <a
            href="mailto:shivbratmishra@photonsoftwares.com"
            className="text-blue-600 hover:underline"
          >
            shivbratmishra@photonsoftwares.com
          </a>
        </p>
        <p>
          Website:{' '}
          <a
            href="https://photonsoftwares.com"
            className="text-blue-600 hover:underline"
          >
            https://photonsoftwares.com
          </a>
        </p>
      </section>
    </div>
    </div>
  );
}

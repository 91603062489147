import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className="container p-9">  
        <div className='min-h-screen bg-black bg-opacity-50 text-gray-100 p-4 md:p-8' style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>  
            <h1>Privacy Policy</h1>  
       
            <p>Last updated: December 16, 2024</p>  
            <p>  
                This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.  
            </p>  
            <p>  
                We use Your Personal Data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.  
            </p>  

            <h2>Interpretation and Definitions</h2>  
            <h3>Interpretation</h3>  
            <p>  
                The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.  
            </p>  

            <h3>Definitions</h3>  
            <p>For the purposes of this Privacy Policy:</p>  
            <ul>  
                <li><strong>Account:</strong> means a unique account created for You to access our Service or parts of our Service.</li>  
                <li><strong>Application:</strong> refers to mobile App, web app and other software program provided by the Company.</li>  
                <li><strong>Company:</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Photon Software, Noida, UP.</li>  
                <li><strong>Device:</strong> means any device that can access the Service such as a computer, a cellphone, or a digital tablet.</li>  
                <li><strong>Personal Data:</strong> is any information that relates to an identified or identifiable individual.</li>  
                <li><strong>Service:</strong> refers to the Application.</li>  
                <li><strong>Usage Data:</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>  
                <li><strong>You:</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>  
            </ul>  

            <h2>Collecting and Using Your Personal Data</h2>  
            <h3>Types of Data Collected</h3>  
            <h4>Personal Data</h4>  
            <p>  
                While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:  
            </p>  
            <ul>  
                <li>Email address</li>  
                <li>First name and last name</li>  
                <li>Phone number</li>  
                <li>Address, State, Province, ZIP/Postal code, City</li>  
                <li>Current location </li>  
            </ul>  

            <h4>Usage Data</h4>  
            <p>  
                Usage Data is collected automatically when using the Service. Usage Data may include information such as:  
            </p>  
            <ul>  
                <li>Your Device's Internet Protocol address (e.g., IP address)</li>  
                <li>Browser type and version</li>  
                <li>The pages of our Service that You visit</li>  
                <li>The time and date of Your visit</li>  
                <li>The time spent on those pages</li>  
                <li>Unique device identifiers</li>  
                <li>Other diagnostic data</li>  
            </ul>  

            <h3>When You access the Service by or through a mobile device</h3>  
            <p>  
                We may collect certain information automatically, including, but not limited to:  
            </p>  
            <ul>  
                <li>The type of mobile device You use</li>  
                <li>Your mobile device unique ID</li>  
                <li>The IP address of Your mobile device</li>  
                <li>Your mobile operating system</li>  
                <li>The type of mobile Internet browser You use</li>  
                <li>Unique device identifiers</li>  
                <li>Other diagnostic data</li>  
            </ul>  

            <h3>Device or Other IDs</h3>  
            <p>  
            We may collect and use Device or Other IDs (e.g., Advertising ID, Android ID, IMEI, BSSID, MAC address) for purposes such as:  
            </p>  
            <ul>  
                <li>Improving the Service functionality</li>  
                <li>Analyzing app performance</li>  
                <li>Checking Internet Connectivity</li>  
                <li>Supporting user preferences and personalization</li>  
            </ul>  

            <h2>Information Collected During Payment Processing</h2>  
            <p>When you use our app and make a payment through Razorpay, we collect and process certain information required to complete the transaction. This includes:</p>  
            <h3>Personal Information:</h3>  
            <ul>  
                <li>Name</li>  
                <li>Email Address</li>  
                <li>Contact Number</li>  
            </ul>  
            <h3>Transaction Details:</h3>  
            <ul>  
                <li>Payment Amount</li>  
                <li>Currency</li>  
                <li>Order ID or Transaction ID</li>  
            </ul>  
            <h3>Payment Method Details:</h3>  
            <ul>  
                <li>Card type (Visa, MasterCard, etc.)</li>  
                <li>Last 4 digits of the card number (we do not store full card details)</li>  
                <li>UPI ID (if applicable)</li>  
                <li>Wallet information</li>  
                <li>Net banking reference ID</li>  
            </ul>  
            <p>Note: Sensitive payment details like full card numbers or CVV are not stored by us or Razorpay. All payment data is securely processed in compliance with PCI-DSS standards.</p>  

            <h3>Device and Network Information</h3>  
            <p>To ensure a secure and smooth payment experience, the following device and network information may be collected:</p>  
            <ul>  
                <li>Device Model and Operating System Version</li>  
                <li>IP Address (used for fraud prevention and security)</li>  
                <li>Network Information (e.g., Wi-Fi or mobile network)</li>  
            </ul>  

            <h3>What We Do Not Collect below information through Razorpay:</h3>  
            <ul>  
                <li>IMEI (International Mobile Equipment Identity)</li>  
                <li>MAC Address</li>  
                <li>Android ID</li> 
             

            </ul>  
            <p style={{background:"gray", color:"white"}} className='m-0 p-0 bg-gray text-white'>We do not collect or process sensitive device identifiers like IMEI or MAC addresses, in compliance with platform and privacy regulations.</p>  
            <p  style={{background:"gray",color:"white"}} className='m-0 p-0 bg-gray text-white'>However we may collect these information from other library for App functionalty</p> 
            <h3>How the Data is Used</h3>  
            <p>The data collected during payment processing is used for the following purposes:</p>  
            <ul>  
                <li>To complete and verify payment transactions.</li>  
                <li>To ensure the security of the payment process and prevent fraud.</li>  
                <li>To comply with legal and regulatory requirements.</li>  
                <li>To troubleshoot payment issues and provide customer support.</li>  
            </ul>  

            <h3>Data Sharing</h3>  
            <p>We share the collected information with Razorpay, our payment gateway provider, to process transactions securely. Razorpay adheres to strict security standards, including PCI-DSS compliance, to protect sensitive payment data.</p>  

            <h3>Data Retention</h3>  
            <p>Transaction data is retained only for as long as necessary to fulfill legal, regulatory, and operational requirements. Payment method details (like card numbers) are not stored on our servers.</p>  

            <h3>User Consent</h3>  
            <p>By using our app and choosing to make payments through Razorpay, you consent to the collection and processing of the above data for payment processing purposes.</p>  

            <h3>Security Measures</h3>  
            <p>We and our payment provider, Razorpay, implement industry-standard security practices to ensure that your data is encrypted, protected, and processed securely.</p>  

            <h2>Use of Your Personal Data</h2>  
            <ul>  
                <li>Personal Details (name, email, phone number).</li>  
                <li>Transaction Details (amount, currency, order ID).</li>  
                <li>Device and Network Information (IP address, OS version).</li>  
                <li>Payment Method Details (card info, UPI, wallet).</li>  
                <li>Logs and Errors (for debugging).</li>  
            </ul>  

            <h2>Retention of Your Personal Data</h2>  
            <p>  
                The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. Usage Data is retained for internal analysis unless required by law for longer periods.  
            </p>  

            <h2>Sharing Your Personal Data</h2>  
            <p>We may share Your Personal Data in the following situations:</p>  
            <ul>  
                <li>With Service Providers for operational purposes.</li>  
                <li>For business transfers or legal requirements.</li>  
                <li>With affiliates or business partners.</li>  
                <li>With other users if You choose to interact publicly.</li>  
                <li>With Your consent for any other purpose.</li>  
            </ul>  

            <h2>Security of Your Personal Data</h2>  
            <p>  
                We prioritize the security of Your Personal Data and use commercially acceptable means to protect it. However, no method of transmission or storage is completely secure.  
            </p>  

            <h2>Your Privacy Rights</h2>  
            <p>  
                You have the right to access, update, or delete the information We have on You. You can exercise these rights by contacting Us.  
            </p>  

            <h2>Contact Us</h2>  
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>  
            <ul className='p-1'>  
                <li>By email: <a href="mailto:shivbratmishra@gmail.com">shivbratmishra@gmail.com</a>, <a href="mailto:shivbratmishra@photonsoftwares.com">shivbratmishra@photonsoftwares.com</a></li>  
                <li>By phone: 918400063557</li>  
            </ul>  
        </div>  
      </div>  
  )
}

export default PrivacyPolicy
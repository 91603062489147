import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import NavbarContainer from "./components/NavbarContainer";
import Sidebar from "./pages/pages/Sidebar";
import MainContent from "./pages/pages/MainContent";
import Home from "./pages/pages/Home";
import OurServices from "./pages/pages/OurServices";
import Specialisations from "./pages/pages/OurSpecialisations";
import WhyIQ from "./pages/WhyIQ";
import Contactus from "./pages/pages/Contactus";
import OurProduct from "./pages/pages/OurProduct";
import OurWork from "./pages/pages/OurWork";
import Aboutus from "./pages/pages/Aboutus";
import Demoreq from "./pages/pages/Demoreq";
import PrivacyPolicy from "./pages/pages/PrivacyPolicy";
import TermsAndConditions from "./pages/pages/TermsAndConditions";

function App() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [step, setStep] = useState(1);
  useEffect(() => {
    const currentPath = window.location.pathname; // Get the current path
    const rootElement = document.documentElement; // Select the <html> element

    // Set the background image dynamically based on the path
    if (currentPath === '/') {
        rootElement.style.setProperty('--background-image', "url('/public/bghome.jpg')");
    } else {
        rootElement.style.setProperty('--background-image', "url('/public/photonbg.png')");
    }
}, []);
  return (
    <>
      <div
        className={` ${isDesktop ? "flex" : ""} lg:${
          isDesktop ? "flex-col" : "flex-row"
        } lg:flex-row h-auto text-white`}
        style={{
          backgroundImage: "url('/photonbg.png')",

          backgroundSize:
            "cover" /* Ensures the image covers the entire background */,
          backgroundPosition: "center" /* Centers the background image */,
          backgroundRepeat:
            " no-repeat" /* Prevents the image from repeating */,
          backgroundAttachment: "fixed",
        }}
      >
        {isDesktop ? (
          <Sidebar isDesktop={isDesktop} />
        ) : (
          <NavbarContainer isDesktop={isDesktop} />
        )}
        {/* {isDesktop ?  : ""} */}
        <Routes>
          <Route path="/" element={<Home isDesktop={isDesktop} />} />
          <Route
            path="/services"
            element={
              <OurServices
                step={step}
                setStep={setStep}
                isDesktop={isDesktop}
              />
            }
          />
          <Route
            path="/product"
            element={<OurProduct isDesktop={isDesktop} />}
          />
          <Route path="/ourwork" element={<OurWork isDesktop={isDesktop} />} />
          <Route
            path="/Specialisations"
            element={
              <Specialisations
                step={step}
                setStep={setStep}
                isDesktop={isDesktop}
              />
            }
          />
          <Route path="/WhyIQ" element={<WhyIQ isDesktop={isDesktop} />} />
          <Route path="/about-us" element={<Aboutus isDesktop={isDesktop} />} />
          <Route path="/demo" element={<Demoreq isDesktop={isDesktop} />} />

          <Route
            path="/Contactus"
            element={<Contactus isDesktop={isDesktop} />}
          />
          <Route
          path="/Privacy-Policy" 
          element={<PrivacyPolicy isDesktop={isDesktop} />}
          />
          <Route
          path="/Terms-Condition" 
          element={<TermsAndConditions isDesktop={isDesktop} />}
          />

          {/* Add more routes as needed */}
        </Routes>
      </div>
    </>
  );
}

export default App;

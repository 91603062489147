import React, { useCallback, useState } from 'react'
import { motion } from "framer-motion";
import { Box, Typography } from '@mui/material';
import About from '../../components/About';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const variant = {
    initial: {
      // x:100,
      y: 100,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        staggerChildren: 0.1,
      },
    },
  };
const Aboutus = ({isDesktop}) => {
    const [step, setStep] = useState(1)
    const handleStepChange = useCallback((newStep) => {
        setStep(newStep);
      }, []);

      const nextstep=()=>{
        setStep((prevstep)=> prevstep +1)
      }
      const prvestep=()=>{
        setStep((prevstep)=> prevstep -1)
      }
  return (
    <div
      className={`lg:${isDesktop ? " w-3/4" : "w-full"} h-100 p-8 space-y-16`}
    >
      <motion.div
        variants={variant}
        initial="initial"
        whileInView="animate"
        className="space-y-4"
      >
        <Box className="text-left text-darkslateblue  font-text-md-medium ">
          <Box className="text-center text-13xl text-white font-text-md-medium mb-4">
            <Typography
              variant="h3"
              className="text-white font-inherit font-bold  "
            >
              {/* <div className="flex items-center justify-center h-40 w-40 bg-black"> */}
              {/* </div>   */}
              About Us
            </Typography>
            <Typography className="mb-4">
            Transform your brand with our innovative digital solutions that captivate and engage your audience.
            </Typography>
            <div className={`flex flex-wrap justify-center items-center ${isDesktop? "gap-24":"gap-4"}`}>
              <ArrowBackIcon className="cursor-pointer" onClick={()=>{
                if(step >1){
                  prvestep()
                }
              }}/>
    {[1, 2].map((item) => (
      <div
        key={item}
        onClick={() => handleStepChange(item)}
        className={`h-1 ${isDesktop? "w-36":"w-10"} cursor-pointer ${
          step === item ? 'bg-white' : 'bg-gray-600'
        }`}
      />
    ))}
    <ArrowForwardIcon className="cursor-pointer" onClick={()=>{
                if(step <2){
                  nextstep()
                }
              }} />
  </div>
              {/* {step==1
              &&<About/>
              } */}
              {step==1&&
              <div class={`flex ${isDesktop?"flex-row":"flex-col"} items-center justify-between p-6 gap-6`}>
              {/* <!-- Logo Section --> */}
              <div class={` ${isDesktop?"w-full":"w-[70%]"} `}>
                <img src="aboutlogo.png" alt="Photon Softwares Logo" class="w-full  h-[400px]" />
              </div>
              
              {/* <!-- Content Section --> */}
              <div class="w-full md:w-2/3 text-center md:text-left">
                <h2 class="text-2xl font-bold mb-4 text-left">About Photon Software's</h2>
                <p class="text-base md:text-lg leading-relaxed text-left">
                Photon Software’s is a leading provider of Odoo ERP Implementation, Microsoft 365 Central, and bespoke digital transformation solutions, specializing in the retail sector. We enhance digital customer and employee experiences through cutting-edge technologies, offering tailored solutions like custom ERP, product passport, restaurant solutions, point of sale systems, and mobile/web app development. As experts in custom development, we deliver scalable, innovative solutions to empower businesses and drive growth. Experience future-ready technology with Photon Software’s!
                </p>
              </div>
            </div>}
            {step ==2&&
            <div className="bg-black bg-opacity-50 text-white p-8 font-sans">
            <h1 className="text-4xl font-bold text-center mb-12">Leadership</h1>
            <div className={`flex ${isDesktop?'flex-row':'flex-col'} justify-around items-start`}>
              <div className="mb-8 md:mb-0 text-center md:text-left">
                <img
                  src="mishrasir.png?height=150&width=150"
                  alt="Shivbrat Mishra"
                  width={150}
                  height={150}
                  className="rounded-full mx-auto md:mx-0 mb-4"
                />
                <h2 className="text-xl font-semibold mb-2">Shivbrat Mishra (India)</h2>
                  <h6>CIO</h6>
                <ul className="list-disc list-inside  space-y-1 text-left  text-[16px]">
                  <li>25+ years' Experience.</li>
                  <li>Global Retail Tech Leader.</li>
                  <li>15 years in the Middle East.</li>
                  <li>COE IT Head - Landmark Group, TESCO, DS Group</li>
                  <li>Product Development expertise, Oracle Retail Implementation and support (v12, v13)</li>
                  <li>Solution Implementation, development, maintenance and support.</li>
                </ul>
              </div>
              <div className="text-center md:text-left">
                <img
                  src="Navdeepsir.png?height=150&width=150"
                  alt="Navdeep Kundra"
                  width={150}
                  height={150}
                  className="rounded-full mx-auto md:mx-0 mb-4"
                />
                <h2 className="text-xl font-semibold mb-2">Navdeep Kundra (Australia)</h2>
                  <h6>Technology Advisor</h6>
                <ul className="list-disc list-inside text-sm space-y-1 text-left text-[16px]">
                  <li>25+ years' Experience.</li>
                  <li>Global Retail Leader.</li>
                  <li>Experience as a Retailer and Consultant.</li>
                  <li>Implemented the First Oracle Retail in ME.</li>
                  <li>Landmark, TESCO, KOJ, Masters Home Improvement, David Jones + 10 others.</li>
                  <li>Service Delivery, Architecture, Strategy, Roadmap, IT Op Model and Execution.</li>
                  <li>20 yrs. with Oracle Retail (V9 to V13).</li>
                </ul>
              </div>
              <div className="text-center md:text-left">
                <img
                  src="Javed.png?height=150&width=150"
                  alt="Javed Akhtar"
                  width={150}
                  height={150}
                  className="rounded-full mx-auto md:mx-0 mb-4"
                />
                <h2 className="text-xl font-semibold mb-2">Javed Akhtar (Canada)</h2>
                  <h6>VP Technology North America</h6>
                <ul className="list-disc list-inside text-sm space-y-1 text-left text-[16px]">
                  <li>Delivering cutting-edge, scalable and future-ready business solutions shaped by 25 years in  Information technology.</li>
                  <li>Combining strategic vision and technical expertise to redefine cloud transformation</li>
                  <li>Delivering seamless cloud solution design and implementation to solve real-world challenges.</li>
                  <li>IT Head - Landmark Group</li>
                  <li>ERP Implementation and support</li>
                  <li>Solution Implementation, development, maintenance and support.</li>
                </ul>
              </div>
            </div>
          </div>}

            
          </Box>
          </Box>

      </motion.div>
      </div>
  )
}

export default Aboutus